import React, { useState } from 'react';
import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';
import type { DraftContent } from 'ricos-content';
import { postActionClick } from '@wix/bi-logger-groups/v2';
import { useSettings } from '@wix/tpa-settings/react';
import { EditorEventsProvider } from 'wix-rich-content-editor-common/dist/lib/EditorEventsContext';

import { selectGroupSlugById, selectIsGroupSecret } from 'store/selectors';
import { useController } from 'common/context/controller';
import { useHref } from 'router/react';
import type { IFeedItem } from 'api/feed/types';

import {
  More as MoreIcon,
  Share as ShareIcon,
} from '@wix/wix-ui-icons-common/on-stage';
import { Menu, MenuItem } from 'wui/Menu';
import { Show } from 'wui/Show';
import { Wire } from 'wui/Wire';
import { ButtonGroup } from 'wui/ButtonGroup';
import { ShareDialog } from 'wui/ShareDialog';
import { IconButton } from 'wui/IconButton';

import * as feed from 'settings/feed';

import { FeedItemEditorDialog } from '../FeedItemEditorDialog';
import { DeleteFeedItemDialog } from './DeleteFeedItemDialog';

import classes from './FeedItem.scss';

interface IProps {
  item: IFeedItem;
}

interface IDialogsState {
  edit?: boolean;
  share?: boolean;
  delete?: boolean;
}

export function FeedItemMenu(props: IProps) {
  const { item } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { feed$ } = useController();
  const settings = useSettings();
  const { experiments } = useExperiments();

  const { permissions } = item;
  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;
  const isPinned = !!item.pin;
  const isFollowing = !!item.requesterContext?.subscribed;

  const [dialogs, setDialogs] = useState<IDialogsState>({
    edit: false,
    share: false,
    delete: false,
  });

  const slug = useSelector(selectGroupSlugById(groupId));
  const isGroupSecret = useSelector(selectIsGroupSecret(groupId));
  const feedItemUrl = useHref(
    'group.discussion.post',
    { slug, feedItemId },
    { absolute: true },
  );

  return (
    <ButtonGroup>
      <Show
        if={
          Boolean(permissions?.canShare) &&
          !isMobile &&
          !isGroupSecret &&
          !experiments.enabled('specs.groups.NewReactions')
        }
      >
        <Wire
          cssVarName="showShareButton--inline-block"
          legacyFallback={settings.get(feed.settings.showShareButton)}
        >
          <IconButton
            className={classes.iconButton}
            icon={<ShareIcon />}
            onClick={dialogOpenHandler('share')}
            aria-label={t('groups-web.discussion.feed.post-actions.share')}
          />
        </Wire>
      </Show>
      <Menu
        element={
          <IconButton
            className={classes.iconButton}
            icon={<MoreIcon />}
            aria-label={t('groups-web.a11y.more-actions')}
          />
        }
      >
        {permissions?.canPin &&
          (isPinned ? (
            <MenuItem
              onClick={handleUnpin}
              content={t('groups-web.discussion.feed.post-actions.unpin-post')}
            />
          ) : (
            <MenuItem
              onClick={handlePin}
              content={t('groups-web.discussion.feed.post-actions.pin-post')}
            />
          ))}

        {isFollowing ? (
          <MenuItem
            onClick={handleUnfollow}
            content={t('groups-web.discussion.feed.post-actions.following')}
          />
        ) : (
          <MenuItem
            onClick={handleFollow}
            content={t('groups-web.discussion.feed.post-actions.follow')}
          />
        )}

        {Boolean(permissions?.canShare) && !isGroupSecret && (
          <Wire
            cssVarName="showShareButton--inline-block"
            legacyFallback={settings.get(feed.settings.showShareButton)}
          >
            <MenuItem
              onClick={handleShare}
              content={t('groups-web.discussion.feed.post-actions.share')}
            />
          </Wire>
        )}

        {permissions?.canUpdate && (
          <MenuItem
            onClick={handleEdit}
            content={t('groups-web.discussion.feed.post-actions.edit')}
          />
        )}

        {permissions?.canDelete && (
          <MenuItem
            onClick={handleDelete}
            content={t('groups-web.discussion.feed.post-actions.delete')}
          />
        )}
      </Menu>
      <DeleteFeedItemDialog
        item={item}
        isOpen={dialogs.delete}
        onClose={dialogCloseHandler('delete')}
      />
      <ShareDialog
        data={{ url: feedItemUrl }}
        isOpen={dialogs.share}
        canUseNative={isMobile}
        onClose={dialogCloseHandler('delete')}
        title={t('groups-web.discussion.feed.post-actions.share.dialog')}
      />
      <EditorEventsProvider>
        <FeedItemEditorDialog
          item={item}
          groupId={groupId}
          isOpen={dialogs.edit}
          onClose={dialogCloseHandler('edit')}
        />
      </EditorEventsProvider>
    </ButtonGroup>
  );

  function dialogOpenHandler(dialog: keyof typeof dialogs) {
    return () => openDialog(dialog);
  }

  function dialogCloseHandler(dialog: keyof typeof dialogs) {
    return () => closeDialog(dialog);
  }

  function openDialog(dialog: keyof typeof dialogs) {
    setDialogs({ [dialog]: true });
  }

  function closeDialog(dialog: keyof typeof dialogs) {
    setDialogs({ [dialog]: false });
  }

  function handlePin() {
    reportAction('pin');

    feed$.pin(groupId, item.feedItemId as string);
  }

  function handleUnpin() {
    reportAction('unpin');

    feed$.unpin(groupId, item.feedItemId as string);
  }

  function handleFollow() {
    reportAction('follow');

    feed$.subscribe(groupId, item.feedItemId as string);
  }

  function handleUnfollow() {
    reportAction('unfollow');

    feed$.unsubscribe(groupId, item.feedItemId as string);
  }

  function handleDelete() {
    openDialog('delete');
    reportAction('delete');
  }

  function handleEdit() {
    openDialog('edit');
    reportAction('edit');
  }

  function handleShare() {
    openDialog('share');
    reportAction('share');
  }

  function update(content: DraftContent, topicIds: string[]) {
    feed$.update(
      groupId,
      item.feedItemId as string,
      JSON.stringify(content),
      topicIds,
    );
  }

  function reportAction(action: string) {
    bi.report(
      postActionClick({
        action,
        groupId,
        postId: feedItemId,
      }),
    );
  }
}

FeedItemMenu.displayName = 'FeedItemMenu';

import { useMemo } from 'react';

import { pluginGiphy } from 'wix-rich-content-plugin-giphy/loadable/viewer';
import { pluginImage } from 'wix-rich-content-plugin-image/loadable/viewer';
import { pluginFileUpload } from 'wix-rich-content-plugin-file-upload/loadable/viewer';
import { pluginLinkPreview } from 'wix-rich-content-plugin-link-preview/loadable/viewer';
import { pluginPoll } from 'wix-rich-content-plugin-social-polls/loadable/viewer';
import { pluginGallery } from 'wix-rich-content-plugin-gallery/loadable/viewer';
import { pluginLink } from 'wix-rich-content-plugin-link/loadable/viewer';
import { pluginVideo } from 'wix-rich-content-plugin-video/loadable/viewer';
import { pluginHtml } from 'wix-rich-content-plugin-html/loadable/viewer';
import { pluginEmoji } from 'wix-rich-content-plugin-emoji/viewer';
import { pluginMentions } from 'wix-rich-content-plugin-mentions/loadable/viewer';
import { pluginVerticalEmbed } from 'wix-rich-content-plugin-vertical-embed/loadable/viewer';
import { pluginDivider } from 'wix-rich-content-plugin-divider/loadable/viewer';
import {
  pluginTextColor,
  pluginTextHighlight,
} from 'wix-rich-content-plugin-text-color/viewer';
import { pluginLinkButton } from 'wix-rich-content-plugin-button/loadable/viewer';
import {
  pluginSpoiler,
  SpoilerViewerWrapper,
  initSpoilersContentState,
} from 'wix-rich-content-plugin-spoiler/viewer';
import { pluginLineSpacing } from 'wix-rich-content-plugin-line-spacing/viewer';

import { useMentions } from './useMentions';
import { usePolls } from './usePolls';

export function useViewerPlugins(groupId: string) {
  const pollsHelpers = usePolls(groupId);
  const mentionsHelpers = useMentions(groupId);

  return useMemo(
    () => [
      pluginImage(),
      pluginGiphy(),
      pluginFileUpload(),
      pluginEmoji(),
      pluginMentions(mentionsHelpers),
      pluginLink(),
      pluginLinkPreview(),
      pluginVerticalEmbed(),
      pluginDivider(),
      pluginHtml(),
      pluginTextColor(),
      pluginTextHighlight(),
      pluginLinkButton(),
      pluginPoll({
        ...pollsHelpers,
        voteRole: 'SITE_MEMBERS',
        showVoteRoleSetting: false,
      }),
      pluginGallery(),
      pluginVideo(),
      pluginSpoiler({
        SpoilerViewerWrapper,
        initSpoilersContentState,
      }),
      pluginLineSpacing(),
    ],
    [groupId],
  );
}

import React, { useMemo } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { safeJsonParse } from 'wix-rich-content-common';

import type { IFeedItem } from 'api/feed/types';

import { CardContent } from 'wui/CardContent';

import { RichContentViewer } from 'common/components/RichContent/Viewer';

import { ActivityContent } from './ActivityContent';
import { getAriaId } from './a11y';

interface IProps {
  item: IFeedItem;
  truncate?: boolean;
}

export function FeedItemContent(props: IProps) {
  const { item, truncate } = props;

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;
  const content = useMemo(
    () => safeJsonParse(item.entity?.body?.content as string),
    [item.entity?.body?.content as string],
  );

  if (item.activity) {
    return <ActivityContent item={item} />;
  }

  if (!content) {
    return null;
  }

  return (
    <CardContent id={getAriaId(feedItemId, 'content')} as="div">
      <RichContentViewer
        usage="FeedItem"
        groupId={groupId}
        truncate={truncate}
        content={content}
        contentId={item.feedItemId}
      />
    </CardContent>
  );
}

FeedItemContent.displayName = 'FeedItemContent';

import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { IMediaData } from 'api/feed/types';

import {
  Video as VideoIcon,
  Image as ImageIcon,
  Attachment as AttachmentIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import { Typography } from 'wui/Typography';
import { Box } from 'wui/Box';
import { Show } from 'wui/Show';

import classes from '../FeedItem.scss';

interface IProps {
  stats?: IMediaData;
}

export function Attachments({ stats }: IProps) {
  const { isMobile } = useEnvironment();

  return (
    <Box gap="SP4">
      <Show if={Boolean(stats?.imageCount)}>
        <Box>
          <Typography className={classes.disclaimerText}>
            {stats?.imageCount}
          </Typography>
          <ImageIcon size={24} className={classes.icon} />
        </Box>
      </Show>

      <Show if={Boolean(stats?.videoCount)}>
        <Box>
          <Typography className={classes.disclaimerText}>
            {stats?.videoCount}
          </Typography>
          <VideoIcon size={24} className={classes.icon} />
        </Box>
      </Show>

      <Show if={Boolean(stats?.fileCount && !isMobile)}>
        <Box>
          <Typography className={classes.disclaimerText}>
            {stats?.fileCount}
          </Typography>
          <AttachmentIcon size={24} className={classes.icon} />
        </Box>
      </Show>
    </Box>
  );
}

Attachments.displayName = 'Attachments';
Attachments.defaultProps = {
  stats: {},
};
